import priceFormat from './priceFormat';

export const viewItemListDL = (name, items = []) => {
  // @ts-ignore
  dataLayer.push({ ecommerce: null });
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_view_item_list',
    ecommerce: {
      item_list_name: name,
      items,
    },
  });
};

export const viewItemDL = (productDetails) => {
  let productOptions = getProductCategories(productDetails.skuSuffix.split('-'), productDetails.variant);

  // @ts-ignore
  dataLayer.push({ ecommerce: null });
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_view_item',
    ecommerce: {
      currency: 'USD',
      value: productDetails.price / 100,
      items: [
        cleanObject({
          item_id: productDetails.productCode,
          item_name: productDetails.name,
          item_variant: productDetails.variant,
          price: productDetails.price / 100,
          item_group: productDetails.item_group,
          trade_discount: productDetails.trade_discount,
          quantity: 1,
          ...productOptions,
        }),
      ],
    },
  });
};

export const addToCartDL = (item) => {
  const productOptions = item.url.split('/').pop().split('-');
  const categories = getProductCategories(productOptions, item.variant);
  const itemData = cleanObject({
    item_id: item.id,
    item_name: item.name,
    item_variant: item.variant,
    price: item.price,
    quantity: item.quantity,
    trade_discount: item.trade_discount,
    item_group: getItemGroup(item.id, item.variant),
    ...categories,
  });

  // @ts-ignore
  dataLayer.push({ ecommerce: null });
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_add_to_cart',
    ecommerce: {
      currency: 'USD',
      value: item.totalPrice,
      items: {...itemData},
    },
  });
};

export const removeFromCartDL = (item) => {
  const productOptions = item.url.split('/').pop().split('-');
  const categories = getProductCategories(productOptions, item.variant);
  const itemData = cleanObject({
    item_id: item.id,
    item_name: item.name,
    item_variant: item.variant,
    price: item.price,
    quantity: item.quantity,
    trade_discount: item.trade_discount,
    item_group: getItemGroup(item.id, item.variant),
    ...categories,
  });

  // @ts-ignore
  dataLayer.push({ ecommerce: null });
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_remove_from_cart',
    ecommerce: {
      currency: 'USD',
      value: item.totalPrice,
      items: {...itemData},
    },
  });
};

export const beginCheckoutDL = (items, totalPrice) => {
  // @ts-ignore
  dataLayer.push({ ecommerce: null });
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_begin_checkout',
    ecommerce: {
      currency: 'USD',
      value: totalPrice,
      items: items.map(i => cleanObject(i)),
    },
  });
};

export const viewCartDL = (items, totalPrice) => {
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_view_cart',
    ecommerce: {
      currency: 'USD',
      value: totalPrice,
      items: items.map(i => cleanObject(i)),
    },
  });
};

export const purchaseCompleteDL = (
  items,
  subTotal,
  totalPrice,
  orderDetails,
  userDetails,
) => {
  // @ts-ignore
  dataLayer.push({ ecommerce: null });
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_purchase',
    ecommerce: {
      currency: 'USD',
      value: subTotal,
      total: totalPrice,
      items: items.map(i => cleanObject(i)),
      ...orderDetails,
    },
    customer: userDetails,
  });
};

export const searchDL = (keyword) => {
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_search',
    search_terms: keyword,
  });
};

export const signUpSuccessDL = (email, method) => {
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_signup',
    user_email: email,
    method,
  });
};

export const newsletterSubscribeDL = (email, method) => {
  // @ts-ignore
  dataLayer.push({
    event: 'rbw_newsletter_signup',
    user_email: email,
    method,
  });
};

const getProductCode = (product) => {
  let code = product.partial_sku;
  let suffix = product.slug.current.split('/')[2];
  return (code + '-' + suffix).toUpperCase();
};

const cleanObject = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== null && value !== undefined && value !== '')
  );
};

export const getProductCategories = (productOptionArray, variant) => {
  if (['Finish Sample', 'Accessory'].includes(variant) || !productOptionArray) return null;
  //map productOptionList to add the values for categories item_category, item_category2...
  const categories = productOptionArray.reduce((acc, item, index) => {
    acc[`item_category${index < 1 ? '' : index + 1}`] = item.toUpperCase();
    return acc;
  }, {});

  return categories;
};

export const getItemGroup = (id, variant) => {
  if (!['Finish Sample', 'Accessory'].includes(variant)) {
    return id.split('-')[0];
  }

  return '';
};
